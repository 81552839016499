<template>
    <div>
        <Navigation />
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>

                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_title ai_title="“一言一文”AI市场分析报告" />
                        <ai_switch :select_version="form.select_version" tab1_name="基础版" tab2_name="专业版"
                            @select_versionShow="select_versionShow" />
                        <ai_select selectTitle="报告种类" :select_list="report_type_list" @getSelectVal="get_report_type" />
                        <ai_select selectTitle="所属行业" :select_list="report_industry_list"
                            @getSelectVal="get_interview" />
                        <div class="form_ct flex">
                            <p class="upLoad_text">产品/服务：</p>
                            <div class="upload_box">
                                <el-input v-model="form.report_service"
                                    placeholder="请录入您的主要产品或服务。如：企宣通媒介宣发系统！"></el-input>
                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">主题：</p>
                            <div class="upload_box">
                                <el-input resize="none" show-word-limit type="textarea" :rows="3"
                                    v-model="form.report_theme" placeholder="请录入您针对此产品想要分析的主题。如：未来发展趋势"></el-input>
                            </div>
                        </div>
                        <div v-show="form.select_version == '2'">
                            <ai_multiterm title="提供数据" input_placeholder="请输入具体数据" @postArr="postArr" />
                        </div>
                        <ai_button_file_data ref="ai_button_file_data" @file_data="file_data" />
                        <ai_text_content :contentData="contentData" />
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_select from '@/components/ai_select'
import ai_title from '@/components/ai_title'
import ai_switch from '@/components/ai_switch'
import ai_text_content from '@/components/ai_text_content'
import ai_button_file_data from '@/components/ai_button_file_data'
import ai_multiterm from '@/components/ai_multiterm.vue'
export default {
    mounted() { this.getUserInfo() },
    components: {
        ai_title,
        wordNav,
        ai_select,
        ai_switch,
        ai_multiterm,
        ai_text_content,
        ai_button_file_data
    },
    data() {
        return {
            form: {
                select_version: "1",
                report_type: '', //  # 报告种类
                report_industry: '',  //# 所属行业
                report_service: '',  //# 产品/服务
                report_theme: '',  //# 主题
                report_data: []  //# 报告种类(数组类型)
            },
            report_type_list: [
                '市场概况报告', '目标市场报告', '竞争对手分析报告', '产品市场调研报告', '消费者调研报告', '行业趋势分析报告', '市场定位报告', '场营销策略报告', '市场分析报告'
            ],
            report_industry_list: ['科技', '教育', '专题', '历史', '生活', '宣传', '其他', '党政党建', '餐饮美食', '金融理财', '体育运动', '家具装修', '旅游旅行', '医学医疗', '汽车交通', '工业', '美容', '房地产', '媒体宣发', '全行业'],
            contentData: {}, //内容

            aiNumShow: false,
            aiPoupShow: false,

        }
    },
    methods: {
        select_versionShow(id) {
            this.form = {
                select_version: id,
                report_type: '', //  # 报告种类
                report_industry: '',  //# 所属行业
                report_service: '',  //# 产品/服务
                report_theme: '',  //# 主题
                report_data: []  //# 报告种类(数组类型)
            }
        },
        get_report_type(val) {
            this.form.report_type = val
        },
        get_interview(val) {
            this.form.report_industry = val
        },
        postArr(arr) {
            this.form.report_data = arr
        },
        // 获取用户信息
        getUserInfo() {
            let $user_info = JSON.parse(localStorage.getItem('user_info'))
            if ($user_info) {
                this.curlGet('/api/user/info').then((res) => {
                    if (res.data.code) {
                        this.$user_info = res.data.data
                        this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                    }
                })
            }
        },
        file_data() {
            // if (this.form.type == '') {
            //     return this.$message({
            //         message: '请输入从事行业',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            // if (this.form.business == '') {
            //     return this.$message({
            //         message: '请输入标题',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            // if (this.form.keyword == '') {
            //     return this.$message({
            //         message: '请输入关键字',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }

        },
        postData() {
            this.aiNumShow = false
            this.$refs.ai_button_file_data.loading_show()
            let data = {}
            data.select_version = this.form.select_version
            data.report_type = this.form.report_type
            data.report_industry = this.form.report_industry
            data.report_service = this.form.report_service
            data.report_theme = this.form.report_theme
            if (this.form.select_version == '2') {
                data.report_data = this.form.report_data
            }
            data.mobile = this.$user_info.mobile
            this.curlPost('/file/create_report', data).then(res => {
                this.$refs.ai_button_file_data.loading_show()
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()

                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>